import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import TempRedirect from './components/temp-redirect';
import MainRedirect from './components/main-redirect';
import FeedPost from './components/feed-post';

import history from './history';

function App() {
  const reload = () => window.location.reload();
  return (
    <>
    <Router history={history}>
      <Switch>
        <Route path='/' exact component={MainRedirect}/>
        <Route path='/apple-app-site-association' onEnter={reload}/>
        <Route path='/:link' exact component={TempRedirect}/>
        <Route path='/p/:link' exact component={FeedPost}/>
      </Switch>
    </Router>
    </>
  );
}

export default App;
