import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import up from '../../assets/images/up.svg';
import commentBubble from '../../assets/images/commentBlackSpeechBubble.svg';
import blurryMessages from '../../assets/images/blurryMessages2.jpg'
import Navbar from '../navbar/navbar';

import './style.css'
import ReactTimeAgo from 'react-time-ago';

function FeedPost(props) {
  const link = props.match.params.link
  // const urlSchemeLink = process.env.REACT_APP_IOS_URL_SCHEME + 'feed?suffix=' + link
  // const appStoreLink = 'https://apps.apple.com/us/app/vama/id1600580466'
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(true);
  const [dynamicLink, setDynamicLink] = useState('');

  const fetchFeedPostData = (link) => {
      const url = `/public/feed/posts/link?linkSuffix=${link}`;
  
      setLoading(true);
      axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
      axios
        .get(url)
        .then(res => {
          if (res.status === 200) {
            setPostData(res.data);
            setDynamicLink(res.data.dynamicLink);
            setLoading(false);
          }
        }, err => {
          setPostData({});
          setLoading(false);
          setDynamicLink('');
          window.location.href = 'https://vama.com'
        })
    }

    useEffect(() => {
      if (link) {
        fetchFeedPostData(link);
      }
    }, [link])


  function conversationPost(postData) {
    return (
    <> 
      {creatorBanner(postData.creator, postData.postCreatedAt)}
      <div id='conversation-container'>
        <img src={blurryMessages} id='blurry-messages' alt='Blurry Messages'></img>
        <a href={dynamicLink}>
          <div id='blurry-messages-overlay'>
            <div>View this post in Vama</div>
          </div>
        </a>
      </div>
    </>
    )
  }

  function userProfileAvatar(user) {
      return !user.profileAvatar ?
          <div className='creator-initials-avatar'>{user.firstName.toUpperCase().charAt(0) + user.lastName.toUpperCase().charAt(0)}</div>
        :
          <img className='creatorAvatar' src={user.profileAvatar} alt='Creator Avatar'/>
  }

  function creatorBanner(creatorUser, postCreatedAt) {
    return (
    <>  
    <div className='creator-container'>
      <a href={dynamicLink}>
        {userProfileAvatar(creatorUser)}
      </a>
      <div>
        <a href={dynamicLink}>
          <div id='creator-name'>{creatorUser.firstName} {creatorUser.lastName}</div>
        </a>
        <div className='timeAgo'><ReactTimeAgo date={Date.parse(postCreatedAt)} locale='en-US' timeStyle='twitter'/></div>
      </div>
    </div>
    </>
    )
  }

  function textContent(postTextContent) {
    return postTextContent ? 
      <div className='postTextContent'>{postTextContent}</div>
      : <></>
  }

  function imageContent(postImage) {
    return postImage ? 
      <img src={postImage.url}id='post-image-content'></img>
      : <></>
  }

  function interactionBar(numVotes, numComments) {
    return (
      <div className='interaction-bar-container'>
        <div className='vote-cell'>
          {/* we use the upvote image and rotate it in css */}
          <a href={dynamicLink}>
            <img src={up} id='downvote'></img>
          </a>
          {numVotes}
          <a href={dynamicLink}>
            <img src={up} id='upvote'></img>
          </a>
        </div>
          <div className='comments-cell'>
            <a href={dynamicLink}>
              <img src={commentBubble} id='comment-bubble'></img>
            </a>
            {numComments}
          </div>
      </div>
    )
  }

  function feedPostPage(postData) {
    const votes = Number(postData.postNumUpvotes) - Number(postData.postNumDownvotes)
    return (
      <div>
      {
        !loading && postData.postCreatedAt ? 

          <div className='post-container'>
            {
              postData.isConversation ?
                conversationPost(postData)
              :
              <>
              {creatorBanner(postData.creator, postData.postCreatedAt)}
              {textContent(postData.postTextContent)}
              {imageContent(postData.image)}
              </>
            }

            {interactionBar(votes, postData.numComments)}
          </div>
        : 
          <div></div>
      }
      </div>
    )}

    function viewInVamaFooter() {
      return (
      <>
        <div id='view-in-vama-footer'>
          <a id='view-in-vama-button' href={dynamicLink}>
            <div id='inner-view-in-vama'>VIEW IN VAMA</div>
          </a>
        </div>
      </>
      )
    }

    return (
      <>
          <Navbar/>
          {
            postData.postCreatedAt ? 
              feedPostPage(postData) 
            : 
              <div></div>
          }
          {viewInVamaFooter()}
      </>
    )
}

export default FeedPost